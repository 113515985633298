import { supabase } from "../utils/supabaseClient"

export default function changeDateFormat(published) {
    //yyyy-mm-ddT00:00:00+00:00 --> M dd, yyyy, h:mm A
    let date = new Date(published)
    date = date.toString().split(/\s(.+)/)[1]
    const dates = date.split(" ")
    date = dates[0] + " " + dates[1] + ", " + dates[2]
    return date
}

export function truncateString(str, length) {
    return str.length > length ? str.substring(0, length - 3) + '...' : str
}

export async function toggleLikes(e, bool, projectId, profileId) {
        const row_id = projectId
        if (bool) {
            const { data: like, error2}  = await supabase
                .from('main_like')
                .insert({profile: profileId, project: projectId})

            //update project count
            const { data, error } = await supabase
                .rpc('increment_project_likes', {
                    row_id
                }, { count: 'exact' })
            if (error) console.error(error)
        } else {
            console.log(false)
            const { data: like, error} = await supabase
                .from('main_like')
                .delete()
                .match({profile: profileId, project: projectId})
            
            console.log(error)

            //update project_count
            const { data, error2 } = await supabase
                .rpc('reduce_project_likes', {
                    row_id
            }, { count: 'exact' })
            if (error) console.error(error)
        }
}

export function shallowEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
            return false;
            }
        }
        return true;
    }

export async function fetcher(table, query, param, id){
    if (!param && ! id) {
        const {data, error} = await supabase
        .from(table)
        .select(query)

        error && console.log(error)

        return data
    } else {
        const {data, error} = await supabase
        .from(table)
        .select(query)
        .eq(param, id)
        
        error && console.log(error)
        return data
    }
                                                                                                             
};

//direcotry should be user_images or article_images
export async function uploadPhoto(e, username, directory){
    console.log("uplaoding photo")
    const file = e.target.files[0];
    const filename = encodeURIComponent(file.name);
    const filepath = "media/images/" + directory + "/" + username + "/" + filename 
    const res = await fetch(`/api/upload/upload-url?file=${filepath}`) 
    const { url, fields } = await res.json();
    const formData = new FormData();

    Object.entries({ ...fields, file }).forEach(([key, value]) => {
      formData.append(key, value);
    });


    const upload = await fetch(url, {
      method: 'POST',
      body: formData
    })


    if (upload.ok) {
      console.log('Uploaded successfully!');
      return filepath
    } else {
      console.error('Upload failed.');
      return null
    }
  };
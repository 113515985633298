import Head from 'next/head'
import Script from 'next/script'

//Could use a package called next-seo
export default function Meta({title, description, image, url}) {
    return (
        <Head>
            <meta name='viewport' content='width=device-width, initial-scale=1' />
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta charSet="utf-8" />
            <link rel='icon' href='/favicon.ico' />
            <link
                rel="canonical"
                href={url}
                key="canonical"
            />
            <meta property="og:type" content="website" /> 
            <meta property="og:site_name" content='https://ordinarycoders.com/' />      
            <meta property="og:image" content={image} />
            <meta property="og:image:alt" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:url" content={url} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@OrdinaryCoders" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            
        </Head>
    )
}

Meta.defaultProps = {
    title: 'Ordinary Coders',
    description: 'Coding can be difficult. Start learning today.',
    image: 'https://d2gdtie5ivbdow.cloudfront.net/static/img/ordinarycoders.PNG',
    url: 'https://ordinarycoders.com/'
}
import articleStyles from '../../styles/Article.module.css'
import ArticleTagList from './ArticleTagList'


//passed into ArticleItem.js
export default function ArticleTagsRead(props) {
    return (
        <p>
            <ArticleTagList article_tags={props.article_tags} /> · 
            <span className={`ms-2 ${articleStyles.readtime}`}>
                {props.reading_time} min read
            </span>
        </p>
    )
}


import articleStyles from '../../styles/Article.module.css'
import { Tag } from 'react-bootstrap-icons'

//TODO:
//1. pass tags as context from index.js

//passed into ArticleTagsReadItem.js
export default function ArticleTagList({article_tags}) {
    return (
        <span className={articleStyles.tag}>
            <Tag /> {article_tags.map((tag) => <span className="me-2" key={tag.id}>{tag.tag_name}</span>)}
        </span>
        
    )
}